import React, { useState } from 'react'
import { graphql, PageProps } from 'gatsby'
import { Hero } from '../../components/site-only/hero'
import { Filter } from '../../components/filter'
import {
  Container,
  Flex,
  Text,
  Grid,
  GridItem,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Box,
  Divider,
  useToken,
  useMediaQuery,
} from '@chakra-ui/react'
import {
  Section,
  SectionContent,
  SectionHeading,
} from '../../components/section'
import {
  HeadingGroup,
  HeadingSubtitle,
  HeadingTitle,
} from '../../components/heading-group'
import { Image } from '../../components/site-only/image'
import { SquareBracket } from '../../components/bracket'
import { Icon } from '../../components/icon'
import {
  Carousel,
  CarouselArrowWrapper,
} from '../../components/carousel'

// @ts-ignore
import HeroImage from '../../images/live_hero.jpg'
import { SlideFadeInView } from '../../components/slide-fade-in-view'
import { Wrapper, WrapperFluid } from '../../components/site-only/wrapper'
import { Circle } from '../../components/circle'

const customCarouselArrow = ({ isPrev, onClick }: any) => {
  const kind = isPrev ? 'left-arrow' : 'right-arrow'
  const margin = isPrev
    ? {
        mr: {
          base: '75%',
          md: '50%',
        },
      }
    : {
        ml: {
          base: '75%',
          md: '50%',
        },
      }

  return (
    <Button onClick={onClick} w={'full'} h={'full'}>
      <Circle
        color={'white'}
        border={1}
        p={{
          base: 5,
          md: 10,
        }}
        {...margin}
      >
        <Icon kind={kind} />
      </Circle>
    </Button>
  )
}

export default function LivePlanningManagement({
  data: { live_planning_management },
}: PageProps<GatsbyTypes.LivePlanningManagementQuery>) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const breakpoint = useToken('breakpoints', 'md')
  const [isDesktop] = useMediaQuery(`(min-width: ${breakpoint})`)
  const [carouselActiveIndex, setCarouselActiveIndex] = useState<number>(0)
  const [modalActiveIndex, setModalActiveIndex] = useState<number>()

  const section = live_planning_management?.section

  return (
    <>
      {section && (
        <>
          <Hero
            bgImage={`url(${HeroImage})`}
            bgSize={'cover'}
            bgPos={'center'}
            pt={90}
            h={{
              base: 300,
              md: 450,
            }}
          >
            <Filter
              type={'opacity'}
              value={0.3}
              bg={'black'}
              position={'absolute'}
              top={0}
              left={0}
              w={'full'}
              h={'full'}
            />
            <Container
              position={'relative'}
              textAlign={{
                base: 'center',
                md: 'unset',
              }}
            >
              <SlideFadeInView>
                <Text
                  display={'inline-block'}
                  textStyle={'lg'}
                  borderBottom={4}
                  pb={2}
                  mb={20}
                  zIndex={1}
                >
                  Service
                </Text>
                <Section>
                  <SectionHeading as={HeadingGroup}>
                    <HeadingTitle>{section.heading?.value}</HeadingTitle>
                    <HeadingSubtitle>
                      {section.subtitle?.element?.value}
                    </HeadingSubtitle>
                  </SectionHeading>
                  <SectionContent
                    dangerouslySetInnerHTML={{
                      __html: section.contents[0]?.value ?? '',
                    }}
                  />
                </Section>
              </SlideFadeInView>
            </Container>
          </Hero>

          <Wrapper
            layerStyle={'primary'}
            textStyle={'md-bold'}
            overflow={'hidden'}
            py={0}
          >
            <Container
              as={Flex}
              position={'relative'}
              textAlign={'center'}
              justifyContent={'center'}
              alignItems={'center'}
              h={94}
            >
              <SlideFadeInView>
                <Text
                  textAlign={'center'}
                  dangerouslySetInnerHTML={{
                    __html: section.contents[1]?.value ?? '',
                  }}
                />
              </SlideFadeInView>
              <Text
                position={'absolute'}
                bottom={{
                  base: 0,
                }}
                fontSize={{
                  base: '13vw',
                  md: '7vw',
                }}
                opacity={0.1}
                lineHeight={1}
                whiteSpace={{
                  md: 'nowrap',
                }}
              >
                {section.subtitle?.element?.value.replace('/', '')}
              </Text>
            </Container>
          </Wrapper>

          <Wrapper invert>
            <Container>
              <SlideFadeInView>
                <Grid
                  templateColumns={{
                    md: '374px 1fr',
                  }}
                  columnGap={25}
                  rowGap={16}
                >
                  <GridItem rowSpan={2}>
                    <WrapperFluid mobileOnly>
                      <Image
                        alt={section.contents[2]?.image?.properties.alt}
                        {...section.contents[2]?.image?.properties.src}
                      />
                    </WrapperFluid>
                  </GridItem>
                  {section.contents
                    .filter((_, index) => index > 2)
                    .map(content => (
                      <GridItem
                        key={content.key}
                        order={{
                          base: -1,
                          md: 'unset',
                        }}
                      >
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: content.value,
                          }}
                        />
                      </GridItem>
                    ))}
                </Grid>
              </SlideFadeInView>

              <WrapperFluid mobileOnly>
                {section.section && (
                  <Section
                    textAlign={'center'}
                    mt={{
                      base: 25,
                      md: 50,
                    }}
                  >
                    <SectionHeading size={'sm'}>
                      <SlideFadeInView>
                        <SquareBracket
                          px={0}
                          py={0}
                          h={30}
                          w={{
                            base: 120,
                            md: 150,
                          }}
                          borderColor={'secondary.500'}
                          reverse
                        >
                          <SquareBracket
                            display={'flex'}
                            alignItems={'center'}
                            px={0}
                            py={0}
                            h={30}
                            w={{
                              base: 120,
                              md: 150,
                            }}
                            borderColor={'secondary.500'}
                          >
                            {section.section.heading?.value}
                          </SquareBracket>
                        </SquareBracket>
                      </SlideFadeInView>
                    </SectionHeading>

                    <Grid
                      templateColumns={{
                        md: 'repeat(auto-fill, minmax(300px, 1fr))',
                      }}
                      rowGap={15}
                      columnGap={8}
                    >
                      {isDesktop ? (
                        section.section.sections.map((section, index) => {
                          const heading = section.heading?.value
                          const subtitle = section.subtitle?.element?.value
                          const image = section.contents[0]?.image?.properties
                          return (
                            <SlideFadeInView key={section.key}>
                              <Section>
                                <SectionHeading as={HeadingGroup} size={'sm'}>
                                  <Divider
                                    as={'div'}
                                    w={30}
                                    mt={4}
                                    mx={'auto'}
                                    border={4}
                                    borderColor={'secondary.500'}
                                  />
                                  <HeadingTitle fontWeight={'normal'}>
                                    {heading}
                                  </HeadingTitle>
                                  <HeadingSubtitle color={'secondary.500'}>
                                    {subtitle}
                                  </HeadingSubtitle>
                                </SectionHeading>
                                <SectionContent
                                  as={'div'}
                                  cursor={'pointer'}
                                  onClickCapture={() => {
                                    setModalActiveIndex(index)
                                  }}
                                  onClick={onOpen}
                                >
                                  <Image
                                    alt={image?.alt}
                                    {...image?.src}
                                    objectFit={'cover'}
                                    h={175}
                                  />
                                </SectionContent>
                                <SectionContent>
                                  <Button
                                    variant={'rounded.outline.secondary'}
                                    size={'md'}
                                    onClickCapture={() => {
                                      setModalActiveIndex(index)
                                    }}
                                    onClick={onOpen}
                                  >
                                    View More
                                    <Icon kind={'right-arrow'} ml={4} />
                                  </Button>
                                </SectionContent>
                              </Section>
                            </SlideFadeInView>
                          )
                        })
                      ) : (
                        <SlideFadeInView>
                          <Section>
                            {section.section.sections
                              .filter(
                                (_, index) =>
                                  isDesktop || index === carouselActiveIndex
                              )
                              .map(section => {
                                const heading = section.heading?.value
                                const subtitle =
                                  section.subtitle?.element?.value

                                return (
                                  <SectionHeading
                                    key={section.key}
                                    as={HeadingGroup}
                                    size={'sm'}
                                  >
                                    <Divider
                                      as={'div'}
                                      w={30}
                                      mt={4}
                                      mx={'auto'}
                                      border={4}
                                      borderColor={'secondary.500'}
                                    />
                                    <HeadingTitle fontWeight={'normal'}>
                                      {heading}
                                    </HeadingTitle>
                                    <HeadingSubtitle color={'secondary.500'}>
                                      {subtitle}
                                    </HeadingSubtitle>
                                  </SectionHeading>
                                )
                              })}
                            <SectionContent
                              as={'div'}
                              w={'100vw'}
                              onClickCapture={() => {
                                setModalActiveIndex(carouselActiveIndex)
                              }}
                              onClick={onOpen}
                            >
                              <Carousel
                                arrows={false}
                                centerMode={true}
                                centerPadding={'10%'}
                                beforeChange={(_, next) => {
                                  setCarouselActiveIndex(next)
                                }}
                              >
                                {section.section?.sections.map(section => {
                                  const image =
                                    section.contents[0]?.image?.properties
                                  return (
                                    <Image
                                      key={section.key}
                                      alt={image?.alt}
                                      {...image?.src}
                                      objectFit={'cover'}
                                      h={175}
                                    />
                                  )
                                })}
                              </Carousel>
                            </SectionContent>
                            <SectionContent>
                              <Button
                                variant={'rounded.outline.secondary'}
                                size={'md'}
                                onClickCapture={() => {
                                  setModalActiveIndex(carouselActiveIndex)
                                }}
                                onClick={onOpen}
                              >
                                View More
                                <Icon kind={'right-arrow'} ml={4} />
                              </Button>
                            </SectionContent>
                          </Section>
                        </SlideFadeInView>
                      )}
                    </Grid>
                  </Section>
                )}
              </WrapperFluid>
            </Container>
          </Wrapper>

          <Modal
            isCentered
            onClose={onClose}
            isOpen={isOpen}
            motionPreset="slideInBottom"
            size={'auto'}
          >
            <ModalOverlay>
              <ModalCloseButton
                as={Button}
                top={10}
                right={10}
                zIndex={1}
                color={'white'}
                variant={'outline'}
              >
                <Icon kind={'close'} size={16} />
              </ModalCloseButton>
            </ModalOverlay>
            <ModalContent>
              <ModalBody>
                <Carousel
                  autoplay={false}
                  fade
                  prevArrow={
                    <CarouselArrowWrapper w={'50vw'} h={'full'}>
                      {customCarouselArrow}
                    </CarouselArrowWrapper>
                  }
                  nextArrow={
                    <CarouselArrowWrapper w={'50vw'} h={'full'}>
                      {customCarouselArrow}
                    </CarouselArrowWrapper>
                  }
                >
                  {section.section?.sections
                    .filter((_, index) => index === modalActiveIndex)
                    .map(section => {
                      return section.contents.map(content => (
                        <Box>
                          <Flex justifyContent={'center'} w={'full'}>
                            <Image
                              alt={content.image?.properties.alt}
                              {...content.image?.properties.src}
                              objectFit={'contain'}
                              w={{
                                base: '80vw',
                              }}
                              maxH={'80vh'}
                            />
                          </Flex>
                        </Box>
                      ))
                    })}
                </Carousel>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  )
}

export const query = graphql`
  query LivePlanningManagement {
    live_planning_management: markdownRemark(
      frontmatter: { title: { eq: "Live planning / Management" } }
    ) {
      id
      childrenMarkdownElement {
        childrenMarkdownElement {
          childrenMarkdownElement {
            childrenMarkdownElement {
              childrenMarkdownElement {
                properties {
                  src {
                    ...ImageFile
                  }
                }
              }
            }
          }
        }
      }
      section: childElement(filter: { type: { eq: "section" } }) {
        tagName
        ...MarkdownHeading
        ...MarkdownSubtitle
        contents: childrenElement(
          filter: {
            type: { eq: "paragraph" }
            children: { type: { nin: ["strong"] } }
          }
        ) {
          key: id
          tagName
          value
          ...MarkdownLink
          ...MarkdownImage
        }
        section: childElement(filter: { type: { eq: "section" } }) {
          key: id
          ...MarkdownHeading
          ...MarkdownSubtitle
          contents: childrenElement(
            filter: {
              type: { eq: "paragraph" }
              children: { type: { nin: ["strong"] } }
            }
          ) {
            key: id
            tagName
            value
            ...MarkdownLink
            ...MarkdownImage
          }
          sections: childrenElement(filter: { type: { eq: "section" } }) {
            key: id
            ...MarkdownHeading
            ...MarkdownSubtitle
            contents: childrenElement(
              filter: {
                type: { eq: "paragraph" }
                children: { type: { nin: ["strong"] } }
              }
            ) {
              key: id
              tagName
              value
              ...MarkdownLink
              ...MarkdownImage
            }
          }
        }
      }
    }
  }
`
