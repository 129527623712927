import React, { forwardRef, ForwardRefExoticComponent } from 'react'
import {
  Circle as ChakraCircle,
  SquareProps,
  ComponentWithAs,
  As,
} from '@chakra-ui/react'

export interface CircleProps extends SquareProps {}

export const Circle: ComponentWithAs<As, CircleProps> = forwardRef(
  (props, ref) => {
    return (
      <ChakraCircle
        display={'inline-flex'}
        overflow={'hidden'}
        ref={ref}
        {...props}
      />
    )
  }
) as ComponentWithAs<As, CircleProps>
