import React from 'react'
import CSS from 'csstype'
import { SquareProps, ComponentWithAs, As } from '@chakra-ui/react'
import { Square } from './square'

export interface SquareBracketProps extends SquareProps {
  bracketSize?: CSS.Property.Width
  reverse?: boolean
}

export const SquareBracket: ComponentWithAs<
  As,
  Omit<SquareBracketProps, '_before' | '_after'>
> = ({
  bracketSize = 12,
  reverse,
  borderColor,
  borderWidth,
  borderRadius,
  ...other
}) => {
  const pos = reverse
    ? {
        _before: {
          bottom: 0,
          left: 0,
          borderBottom: '1px solid currentColor',
          borderLeft: '1px solid currentColor',
          borderWidth,
          borderColor,
          borderRadius,
        },
        _after: {
          top: 0,
          right: 0,
          borderTop: '1px solid currentColor',
          borderRight: '1px solid currentColor',
          borderWidth,
          borderColor,
          borderRadius,
        },
      }
    : {
        _before: {
          top: 0,
          left: 0,
          borderTop: '1px solid currentColor',
          borderLeft: '1px solid currentColor',
          borderWidth,
          borderColor,
          borderRadius,
        },
        _after: {
          bottom: 0,
          right: 0,
          borderBottom: '1px solid currentColor',
          borderRight: '1px solid currentColor',
          borderWidth,
          borderColor,
          borderRadius,
        },
      }

  return (
    <Square
      as={'span'}
      display={'inline-block'}
      position={'relative'}
      boxSizing={'border-box'}
      px={bracketSize}
      py={bracketSize}
      _before={{
        position: 'absolute',
        content: '""',
        width: bracketSize,
        height: bracketSize,
        boxSizing: 'border-box',
        ...pos._before,
      }}
      _after={{
        position: 'absolute',
        content: '""',
        width: bracketSize,
        height: bracketSize,
        boxSizing: 'border-box',
        ...pos._after,
      }}
      {...other}
    />
  )
}
